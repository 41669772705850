import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import Paginate from '../components/paginate';
import HelmetEx from '../components/helmet-ex';
import Banner from '../components/banner';
import Footer from '../components/footer';

import LocalizationService from '../services/localization-service';
import BlogPostOverview from '../components/blog-post-overview';
import { ResourceUtils } from '../utils';

const SECOND_MATCHING_GROUP = 2

const createMap = (prefix, resources) => {
    const prefixEscaped = prefix?.replace(/\./g, '\\.')
    const expression = new RegExp(`(${prefixEscaped}\.)(.*)`);
    const object = {}
    resources.forEach(({ key }) => {
        const property = key.match(expression)?.[SECOND_MATCHING_GROUP]
        if (property) {
            object[property] = property;
        }
    })
    return object
}

const Blog = (props) => {
    const localizationService = new LocalizationService(
        props.data.contentfulResourceSet.resources
    );
    const pageContext = props.pageContext;
    const { allContentfulBlogPost } = props.data;
    const { currentPage, numPages } = pageContext;
    const prevPage =
        currentPage - 1 === 1
            ? '/insights/blog'
            : `/insights/blog/${(currentPage - 1).toString()}`;
    const nextPage = `/insights/blog/${(currentPage + 1).toString()}`;
    const map = useMemo(() => createMap(`blog`, props.data.contentfulResourceSet.resources), [props.data.contentfulResourceSet.resources])
    const microcopy = ResourceUtils.getMicrocopy(localizationService, `blog`, map)
    const { first_page, previous_page, next_page, last_page } = microcopy
    const navigationLabels = { firstPageLabel: first_page, previousPageLabel: previous_page, nextPageLabel: next_page, lastPageLabel: last_page };

    return <Layout>
        <div className="blog-page">
            <HelmetEx>
                <div metadata="title">Polymaths | Blog</div>
                <div metadata="path">/blog</div>
                <div metadata="canonicalurl">/blog</div>
                <div metadata="description">
                    Keep up on the latest in Modern App Development (Cloud Native / Serverless / Modern Web).
                </div>
            </HelmetEx>
            <Banner name={`${localizationService.getString('blog.title')}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;${localizationService.getString('blog.page')} ${currentPage}`} />
            <section className="pt-3">
                <div className="container">
                    {allContentfulBlogPost
                        .edges
                        .map(({ node: article }, index) => <React.Fragment key={index}>
                            <BlogPostOverview
                                title={article.title}
                                description={article.abstract}
                                category={article?.category?.name}
                                gatsbyImageData={article.coverImage?.gatsbyImageData}
                                buttonCaption={localizationService.getString('blog.articles.buttons.readMore')}
                                shareCaption={localizationService.getString('blog.articles.buttons.share')}
                                date={article?.date}
                                author={article?.author?.name}
                                path={`blog/${article.path}`}
                                tags={article.tags}
                            />
                        </React.Fragment>)}
                    <div className="pagination text-center d-flex justify-content-center mt-5">
                        {numPages !== 1 && <Paginate
                            nextPage={nextPage}
                            prevPage={prevPage}
                            allPages={numPages}
                            currentPage={currentPage}
                            baseUrl="insights/blog"
                            {...navigationLabels}
                        />}
                    </div>
                </div>
            </section>
        </div>
        <Footer />
    </Layout>;
};

Blog.propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object
};

export default Blog;

export const query = graphql`
query ($skip: Int!, $limit: Int!, $languageRegEx: String!, $language: String!) {
    site {
      siteMetadata {
        twitterHandle
      }
    }
    contentfulResourceSet(name: {eq: "blog"}, node_locale: {regex: $languageRegEx}) {
        name
        resources {
            key
            value {
                value
            }
        }
    }
    allContentfulBlogPost(sort: {order: DESC, fields: date}, limit: $limit, skip: $skip, filter: {node_locale: {regex: $languageRegEx}}) {
        edges {
            node {
                id
                title
                abstract
                metaDescription
                date(formatString: "MMMM DD, YYYY", locale: $language)
                path
                author {
                    name
                }
                category {
                    name
                }
                coverImage {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
        pageInfo {
            pageCount
            itemCount
            perPage
            hasPreviousPage
            hasNextPage
            currentPage
        }
    }
  }
`;